body {
	padding-top: 77px !important;
	padding-bottom: 94px !important;
}

@media (max-width: 575px) {
	body {
		padding-bottom: 94px !important;
	}
}

@media (max-width: 319px) {
	.d-xss-none {
		display: none;
	}
}

.homePageHeader {
	font-size: 96px;
	font-family: sans-serif;
}

.card-header,
.card-footer a {
	color: #fff;
}

.card-header a.btn {
	background-color: #fff;
}

.widthAuto {
	width: auto !important;
}

.nav-tabs .nav-link.active:hover {
	color: #ccc;
}

.authorisedUser .navbarTop .navbar-brand {
	margin-left: 40px;
}

@media (min-width: 768px) {
	.authorisedUser #pageContent {
		padding-left: 70px !important;
	}
}

.maxW500 {
	max-width: 500px;
	margin: 0 auto;
}

.modal-header {
	background-color: #000048;
}

.modal-title {
	color: #fff;
}

.modal-full-width {
	max-width: 100% !important;
	margin: 30px 10px !important;
}

.modal-90-width {
	max-width: 90% !important;
	margin: 30px auto !important;
}

.modal-max900-width {
	max-width: 900px !important;
	margin: 30px auto !important;
}
