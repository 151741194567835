.navbarTop {
	background: $navbar-top-background;

	.navbar-brand {
		color: $navbar-top-active-color;

		@include hover-focus {
			color: $navbar-top-active-color;
		}
	}

	.nav {
		.nav-link {
			color: $navbar-top-color;
			padding: 0.5rem;

			@include hover-focus {
				color: $navbar-top-hover-color;
			}
		}

		.open > .nav-link,
		.active > .nav-link,
		.nav-link.open,
		.nav-link.active {
			@include plain-hover-focus {
				color: $navbar-top-active-color;
			}
		}
	}

	.navbar-divider {
		background-color: rgba(255, 255, 255, 0.075);
	}
}

.navbarTop.topBarLoggedOut {
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
}

.navbarTop.topBarLoggedOut .navbar-nav {
	flex-direction: row;
}

.navbarTop.topBarLoggedOut .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.navbarBottom {
	width: 100%;
	margin-bottom: 0px;
	bottom: 0px;
	position: absolute;
	z-index: 2;

	background: $navbar-bottom-background;

	.navbar-brand {
		color: $navbar-bottom-active-color;

		@include hover-focus {
			color: $navbar-bottom-active-color;
		}
	}

	.navbar-nav {
		.nav-link {
			color: $navbar-bottom-color;

			@include hover-focus {
				color: $navbar-bottom-hover-color;
			}
		}

		.open > .nav-link,
		.active > .nav-link,
		.nav-link.open,
		.nav-link.active {
			@include plain-hover-focus {
				color: $navbar-bottom-active-color;
			}
		}
	}

	.navbar-divider {
		background-color: rgba(255, 255, 255, 0.075);
	}
}
