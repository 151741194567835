body {
	padding-top: 70px;
	padding-bottom: 70px;
	margin: 0;
	position: relative;
	min-height: 100vh;
}

.red {
	color: #ac0101;
}

@media (min-width: 576px) {
	.nav > li.profile_image > a {
		padding: 5px;
	}
}

.nav > li.profile_image > a img {
	-webkit-border-radius: 8px 8px 8px 8px;
	-moz-border-radius: 8px 8px 8px 8px;
	border-radius: 8px 8px 8px 8px;
	max-height: 28px;
}

.nav > li.profile_image .nav-link {
	padding: 0.375rem;
}

label.uploadButton input[type='file'] {
	position: fixed;
	top: -1000px;
}

.space {
	height: 130px;
	display: block;
}

.page-transition-enter {
	opacity: 0.01;
}

.page-transition-enter.page-transition-enter-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}

.page-transition-leave {
	opacity: 0;
}

.page-transition-leave.page-transition-leave-active {
	opacity: 0;
}

.comment-badge {
	position: relative;
}

.comment-badge[data-count]:after {
	position: absolute;
	right: 0rem;
	top: -0.4rem;
	content: attr(data-count);
	font-size: 50%;
	padding: 0.6em;
	border-radius: 999px;
	line-height: 0.75em;
	color: white;
	background: rgba(255, 0, 0, 0.85);
	text-align: center;
	min-width: 2em;
	font-weight: bold;
	margin-right: -50%;
}

.form-control.av-invalid ~ .invalid-feedback {
	display: block;
}

body.hide-header .navbarTop,
body.hide-footer .navbarBottom {
	display: none;
}

body.hide-header {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
